@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');

:root{
  --primaryColor: #002D62;
  --primaryColorHover: #001A40;
  --secundaryColor: #7FB514;
  --secundaryPale: #70991f;
  --textSecundary: #c5c5c5;
}

body{
  margin:0px;
  padding: 0px;
  background-color: #FFFFFF;
  font-family: 'Lato', sans-serif;
}
*{
  box-sizing: border-box;
}
ul{
  list-style: none;
}
a{
  text-decoration: none;
}
button{
  outline: none;
  border: none;
}
input{
  outline: none;
  border: none;
}

.slider {
  width:100%;
  height:920px;
  margin: 0 auto;
  overflow: hidden;
}
.slides{
  width:400%;
  height:920px;
  display: flex;
}
.slides input{
  display: none;

}
.slide{
  width: 25%;
  position: relative;
  transition: 1s;
}
.slide img{
  width:100%;
  height:920px;

  
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 0;
  
}


.manual-navigation{
  position: absolute;
  width: 100%;
  margin-top: -80px;
  display: flex;
  justify-content: center;
  z-index: 3;
}
.manual-btn{
  border: 2px solid #FFFFFF;
  padding: 5px;
  border-radius: 10px;
  cursor: pointer;
  transition: 1s;
}
.manual-btn:not(:last-child){
  margin-right: 40px;
}
.manual-btn:hover{
  background-color: #FFFFFF;
}

#radio1:checked ~ .first{
  margin-left: 0;
}
#radio2:checked ~ .first{
  margin-left: -25%;
}
#radio3:checked ~ .first{
  margin-left: -50%;
}
#radio4:checked ~ .first{
  margin-left: -75%;
}

.navigation-auto div{
  border: 2px solid var(--primaryColor);
  padding: 5px;
  border-radius: 10px;
  cursor: pointer;
  transition: 1s;
}

.navigation-auto{
  position: absolute;
  width: 100%;
  margin-top: 840px;
  display: flex;
  justify-content: center;
  z-index: 2;
}
.navigation-auto div:not(:last-child){
  margin-right: 40px;
}
#radio1:checked ~ .navigation-auto .auto-btn1{
  background-color: #FFFFFF;
}
#radio2:checked ~ .navigation-auto .auto-btn2{
  background-color: #FFFFFF;
}
#radio3:checked ~ .navigation-auto .auto-btn3{
  background-color: #FFFFFF;
}
#radio4:checked ~ .navigation-auto .auto-btn4{
  background-color: #FFFFFF;
}

.title{
  text-align: center;
  padding: 50px 0 0px;
}
.title h1{
  margin: 0;
  padding: 0;
  font-size: 2rem;
  color:var(--primaryColor);
  font-weight: 600;
  
}

.logo img{
  width: 150px;
}
#main{
  width:100%;
  height:920px;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 0;
}
#main::before{
  position: absolute;
  content: "";
  background: -moz-radial-gradient(center, ellipse cover,#7FB514, rgba(0, 0, 0, 0.6) 100%);
  background: -webkit-radial-gradient(center, ellipse cover,#7FB514, rgba(0, 0, 0, 0.6) 100%);
  background: radial-gradient(ellipse at center,#263C57, rgba(0, 0, 0, 0.4) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#61b50808', endColorstr='#99000000',GradientType=1 );
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
nav{
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  width:100%;
  z-index: 1;
  background-color: transparent;
  transition: 0.6s ease-in-out;
  
}
nav.active{
  box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.157);
  background-color: var(--primaryColor);
}
nav ul{
  display: flex;
}
nav img{
  cursor: pointer;
}
.active{
  background-color: var(--secundaryColor);
  color:#ffffff;
  box-shadow: 2px 4px 10px var(--secundaryPale);
  transition: all ease 0.2s;
}
.active:hover{
  
  color:#ffffff;
  box-shadow: 2px 4px 10px var(--primaryPale);
  transition: all ease 0.2s;
}
nav ul li a{
  font-family: 'Lato';
  height:40px;
  line-height: 43px;
  margin: 3px;
  padding: 0px 22px;
  display: flex;
  font-size: 0.9rem;
  text-transform: uppercase;
  font-weight: 500;
  color:#ffffff;
  letter-spacing: 1px;
  border-radius: 3px;
  transition: 0.2s ease-in-out;
  cursor: pointer;
}
nav ul li a:hover{
  background-color: var(--primaryColor);
  color:#ffffff;
  box-shadow: 2px 4px 10px var(--primaryColorHover);
  transition: all ease 0.2s;
}
nav .menu-btn,
.menu-icon{
      display:none;
  }

  .contForm {
    width: 90%;
    max-width: 600px;
    
    
}
.contForm .left{
  float: left;
  width: 100%;
  box-sizing: border-box;
  border-radius: 5px;
}

.support-form {
  
  padding: 20px;
  border-radius: 10px;
}

.input-group {
  display: inline-block;
  width: 48%;
  margin-bottom: 20px;
  color: #fff;
}

.input-group:nth-child(odd) {
  margin-right: 4%;
}

.input-group-full {
  width: 100%;
  margin-bottom: 20px;
  color: #fff;
}

.label {
  color: white;
  font-size: 14px;
  display: block;
  margin-bottom: 5px;
}

input[type="text"],
input[type="email"],
textarea {
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  border: none;
  background-color: #FFFFFF;
}

textarea {
  resize: none;
  height: 100px;
}

button {
  background-color: #002D62;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
}

button:hover {
  background-color: #001A40;
}





.name{
  text-align: center;
  font-family: 'Montserrat';
  width: 900px;
  position: absolute;
  left: 30%;
  top: 55%;
  transform: translate(-50%,-55%);
  z-index: 2;
}
.name span{
  color: var(--primaryColor);
  font-size: 3rem;
}
.name .details{
  font-size: 18px;
  color: #FFFFFF;
  width: 550px;
  text-align: justify;
  margin-left: 20px;
  display: block;
}
.candidato{
  float: left;
  display: block;
}
.name h2{
  font-family:'Lato';
  font-size: 3rem;
  margin:0px;
  letter-spacing: 2px;
  color:#ffffff;
  
}
.name h1{
  font-family:'Lato';
  font-size: 3rem;
  margin:0px;
  letter-spacing: 2px;
  color:#ffffff;
  
}

.header-btns{
  display: flex;
  margin-top: 40px;
  margin-left: 40%;
}
.header-btn{
  width:160px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  background-color: var(--secundaryColor);
  box-shadow: 5px 10px 30px rgba(255, 2, 2, 0.336);
  border-radius: 5px;
  color:#ffffff;
}
.header-btn:hover{
  background-color: transparent;
  transition: all ease 0.5s;
  color: #ffffff;
  border: 2px solid var(--secundaryColor);
}

#features{
  width:100%;
  height:100%;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 10px ;
  background-color: #001A40;
  z-index: 0;
  background-size: cover;
  
}
#features::before{
  position: absolute;
  content: "";
  background: -moz-radial-gradient(center, ellipse cover,#7FB514, rgba(0, 0, 0, 0.6) 100%);
  background: -webkit-radial-gradient(center, ellipse cover,#7FB514, rgba(0, 0, 0, 0.6) 100%);
  background: radial-gradient(ellipse at center,#263C57, rgba(0, 0, 0, 0.4) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#61b50808', endColorstr='#99000000',GradientType=1 );
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
#features h1{
  color: #FFFFFF;
  font-size: 5rem;
  text-align: center;
}
.a-container{
  display: flex;
  justify-content: center;
  align-content: center;
  max-width: 1500px !important;
  flex-wrap: wrap;

}
.a-box{
  background-color: #FFFFFF;
  width: 350px;
  height: 450px;
  margin: 50px;
  border-radius: 5px;
  overflow: hidden;
  transition: 0.1s ease-in-out;
  position: relative;
  cursor: pointer;
  border: 1px ridge var(--primaryColor);
  box-shadow: 1px 1px 3px var(--primaryColor);
}
.a-b-img{
  width: 100%;
  height: 60%;  

}
.a-b-img img{
  padding: 0px;
  margin-top: 0;
  width:100%;
  height: 90%;
  

}
.a-box:hover{
  border: 1px ridge var(--primaryColor);
  box-shadow: 2px 2px 12px var(--primaryColor);
  
}
.a-b-text{
  width: 100%;
  height: 30%;
  background-color:#FFFFFF;
  display:flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  
}
.a-b-text h2{
  color: var(--primaryColor);
  margin-top: 0;
}
.a-b-text p{
  margin: 0;
  color:var(--textSecundary);
  font-size: 1.1rem;
  font-family:'Lato';
  display: block;
  display: -webkit-box;
  max-width: 80%;
  margin-top: 5px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 10;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0px;
  
}
.a-b-text p:hover{
  color:var(--primaryColor);
  -webkit-line-clamp: 13;
  
}
.a-b-text a{
  margin-top: 10px;
}
#presentaion{
  padding-top: 30px;
  padding-bottom: 30px;
  width:100%;
  height:100%;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('./Assets/contact.jpeg');
  text-align: center;
  z-index: 0;
}
#presentaion::before{
  position: absolute;
  content: "";
  background: -moz-radial-gradient(center, ellipse cover,#7FB514, rgba(0, 0, 0, 0.6) 100%);
  background: -webkit-radial-gradient(center, ellipse cover,#7FB514, rgba(0, 0, 0, 0.6) 100%);
  background: radial-gradient(ellipse at center,#263C57, rgba(0, 0, 0, 0.4) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#61b50808', endColorstr='#99000000',GradientType=1 );
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

#presentaion h1{
  color: #FFFFFF;
  font-size: 5rem;
  text-align: center;
}

#presentaion2{
  padding-top: 30px;
  width:100%;
  height:768px;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #001A40;
  text-align: center;
  z-index: 0;
}
#presentaion2::before{
  position: absolute;
  content: "";
  background: -moz-radial-gradient(center, ellipse cover,#7FB514, rgba(0, 0, 0, 0.6) 100%);
  background: -webkit-radial-gradient(center, ellipse cover,#7FB514, rgba(0, 0, 0, 0.6) 100%);
  background: radial-gradient(ellipse at center,#263C57, rgba(0, 0, 0, 0.4) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#61b50808', endColorstr='#99000000',GradientType=1 );
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.gallery-container{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 2vw;
  padding: 0 2vw;
}
.gallery-items{
  width: 340px;
  height: 340px;
  border: 10px solid #FFF;
  box-shadow: 5px 5px 5px #0006;
  flex-grow: 1;
  transition: transform .5s linear;
}
.gallery-items img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.gallery-items:hover{
  transform: scale(1.1);
}




.containerForm{
  width: 80%;
  max-width: 500px;
  height: auto;
  background: #fff;
  margin: 30px 700px;
  border: 1px solid #fff;
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  overflow: hidden;
  float: none;
}

.containerForm .right{
  float: right;
  width: 100%;
  height: 600px;
  box-sizing: border-box;
  border-radius: 5px;
}

.containerForm .left{
  float: left;
  width: 50%;
  height: 600px;
  background: url('./Assets/contact.jpeg');
  background-size: cover;
  box-sizing: border-box;
  border-radius: 5px;
}

.formBox{
  width: 100%;
  height: 600px;
  padding: 40px 40px;
  box-sizing: border-box;
  background: #fff;
}

.formBox p{
  margin: 0;
  padding: 0;
  float: left;
  font-weight: bold;
  color: var(--primaryColor);

}
.formBox input{
  width: 100%;
  margin-bottom: 40px;
}
.formBox input[type="text"],
.formBox input[type="tel"],
.formBox input[type="email"]
{
  border: none;
  border-bottom: 2px solid var(--primaryColor);
  outline: none;
  height: 40px;
}

.formBox input[type="text"]:focus,
.formBox input[type="tel"]:focus,
.formBox input[type="email"]:focus
{
  border-bottom: 2px solid var(--secundaryColor);
}





.formBox input[type="submit"]
{
  border: none;
  outline: none;
  height: 40px;
  color: #fff;
  background: #262626;
  cursor: pointer;

  
  border-radius: 10px;
  border: none;
  outline: none;
  color: #ffffff;
  background-color:var(--primaryColor);
  cursor: pointer;
}
.formBox input[type="submit"]:hover
{
  background: var(--primaryColorHover); 
  transition: all ease 0.3s;
  color: #ffffff;
}



.pr-heading{
  text-align: center;
  font-family:'Lato';
  width: 600px;
  position: absolute;
  left: 50%;
  top: 55%;
  transform: translate(-50%,-55%);
}
.pr-heading span{
  color: var(--secundaryColor);
}
.pr-heading .details{
  font-size: 23px;
  color: #c5c5c5;
}
.pr-heading .details{
  font-size: 1.2rem;
}
.pr-heading h1{
  font-family:'Lato';
  font-size: 6rem;
  margin:0px;
  letter-spacing: 2px;
  color:#ffffff;
}
.pr-heading h2{
  font-family:'Lato';
  font-size: 3rem;
  margin:0px;
  letter-spacing: 2px;
  color:#ffffff;
}
.pr-btns{
  display: flex;
  margin-top: 40px;
  margin-left: 35%;
}
.pr-btn{
  width:160px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  background-color: var(--secundaryColor);
  box-shadow: 5px 10px 30px var(--secundaryPale);
  border-radius: 5px;
  color:#ffffff;
}
.pr-btn:hover{
  
  transition: all ease 0.5s;
  color: #ffffff;
  border: 2px solid var(--secundaryPale);
}

#about{
  
  width:100%;
  height: 120vh;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 5% 0px 5%;
  position: relative;
  background-color: var(--primaryColor);
}

.about-text{
  width: 45%;
  color: var(--primaryColor);
  list-style: circle;
}
.about-text h1{
  font-size: 5rem;
  color: #FFFFFF;
  font-weight: 600;
  margin: 0px;
  padding: 0px;
}
.about-text p{
  width: 80%;
  font-size: 1.2rem;
  color:var(--textSecundary);
  text-align: justify;
  
}
.about-text button{
  margin-top: 20px;
  width: 140px;
  height: 45px;
  border-radius: 10px;
  border: none;
  outline: none;
  color: #ffffff;
  background-color:var(--secundaryColor);
  cursor: pointer;
}
.about-text button:hover{
  
  background: var(--secundaryPale);
  transition: all ease 0.3s;
  color: #ffffff;
}
.about-image{
  width: 50%;
  font-size: 1.3rem;
  color:var(--textSecundary);
  text-align: center;
}

.about-image img{
width: 500px;
height: 500px;
border-radius: 50%;
}

#contact{
  width:100%;
  display:flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#contact h1{
  color: #fff;
  font-size: 3rem;
}
#contact form{
  width:600px;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}
#contact form input,
#contact form textarea{
  width:100%;
  height:50px;
  margin:5px 0px;
  padding: 10px;
  border: none;
  outline: none;
  background-color: #ffffff2d;
  color: #ffffff;
  border-radius: 5px;
}
#contact form textarea{
  height: 150px;
}
#contact form input[type="submit"]{
  height: 45px;
  background: linear-gradient(90deg, #FF1414 20%,#f70000);
  color: #ffffff;
  text-transform: uppercase;
}
#foot {
  background-color: var(--primaryColor);
  margin: 0;
}
.footer-wrapper {
  
  display: flex;
  
  
}
.footer-logo-container {
  max-width: 110px;
}
.footer-icons {
  margin-top: .5rem;
}
.footer-icons svg {
  font-size: 1.5rem;
  margin-right: 1.25rem;
  color: #FFFFFF;
}
.footer-icons-two {
  margin-top: .5rem;
}
.footer-icons-two svg {
  font-size: 2rem;
  margin-right: 1.25rem;
  color: #FFFFFF;
}
.footer-section-3 {
  flex: 1;
  display: flex;
  justify-content: left;
  margin-left: 40px;
}
.footer-section-one {
  flex: 1;
  display: flex;
  justify-content: left;
  margin-left: 40px;
}

.footer-section-two {
  flex: 1;
  display: flex;
  justify-content: center;
}
.footer-section-center {
  flex: 1;
  display: flex;
  justify-content: center;
  text-align: center;
  padding-bottom: 0;
}
.footer-section-columns {
  display: flex;
  flex-direction: column;
  min-width: 190px;
}
.footer-section-columns span {
  margin: 1.5rem 0rem;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
}
.footer-section-columns img {
  max-width: 150px;
}






@media(max-width: 768px) {
  .containerForm {
    width: 80%;
    margin: 30px 30px;
  }
  .about-text p{
    width: 100%;
    text-align:justify;
}

  .formBox {
    padding: 40px 40px;
  }

  .formBox p {
    font-size: 14px;
  }

  .formBox input[type="text"],
  .formBox input[type="tel"],
  .formBox input[type="email"] {
    height: 35px;
  }

  .formBox input[type="submit"] {
    height: 35px;
  }
}






@media(max-width:620px){
  #contact form{
      width:90%;
  }
  .footer-section-center{
    display: none;
    
  }
  .candidato{
    display: none;
  }
  .footer-section-3{
    
    flex: 1;
  display: absolute;
  justify-content: center;
  text-align: center;
  padding-left: 5px;
  min-width: 300px;
    
  }
  .footer-section-two{
display: none;
  }
  
}

@media(max-width:1190px){
  #main{
      background-size: 1150px !important;
      
  }
  .name{
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      text-align: center;
  }
  .name h2{
      font-size: 1.5rem;
  }
  .name h1{
      font-size: 3rem;
  }
  .name .details{
      font-size: 1rem;
  }
  .pr-heading{
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      text-align: center;
  }
  .pr-heading h2{
      font-size: 1.5rem;
  }
  .pr-heading h1{
      font-size: 3rem;
  }
  .pr-heading .details{
      font-size: 1rem;
  }
  .about-image img{
      height:400px;
  }
  #features{
      height:auto;
  }
  .a-container{
      flex-wrap: wrap;
  }
  .a-box{
      flex-grow: 1;
  }
  .a-b-img img{
      object-fit:contain;
  }
  
  

}

@media(max-width:970px){
  #main{
      height: 650px;
  }
  .name{
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
  }
  .pr-heading{
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
  }
  .about-image{
      display:none;
  }
  .header-btn{
      margin-left: 20%;
      margin-top: 20px;
  }
  .pr-btn{
      margin-left: 20%;
      margin-top: 20px;
  }
  #about{
      justify-content: center;
      padding-top: 0px;
      text-align: center;
      
      margin-top: 0px;
  }
  .about-text{
      width: 90%;
  }
  .about-text h1{
      font-size:4rem;
  }
  .about-text p{
      width: 100%;
  }
  #features{
      margin-bottom: 0px;
  }
}
@media(max-width:600px){
  .name{
      width:60%;
  }
  .pr-heading{
      width:60%;
  }
  #footer-icons{
    display: none;
  }
  nav{
    width: 100%;
  }
}

@media(max-width:1100px){.menu-icon{
  display:block;}
      nav{
          justify-content: space-between;
          height: 65px;
          padding: 0px 30px;
      }
      .logo img{
          width:70px;
      }
      .header-btns{
          margin: 0;
      }
      .pr-btns{
          margin: 0;
      }
      .menu{
          display:none;
          position:absolute;
          top:65px;
          left:0px;
          background-color:var(--primaryColor);
          border-bottom:4px solid var(--secundaryPale);
          width:100%;
          padding:0px;
          margin:0px;
      }
      .menu li{
          width:100%;
      }
      nav .menu li a{
          width:100%;
          height:40px;
          justify-content: center;
          align-items: center;
          margin:0px;
          padding: 25px;
          border:1px solid rgba(38,38,38,0.03);
      }
      nav .menu-icon{
          cursor:pointer;
          float:right;
          padding: 28px 20px;
          position:relative;
          user-select: none;
      }
      nav .menu-icon .nav-icon{
          background-color:#ffffff;
          display:block;
          height:2px;
          position:relative;
          transition: background 0.2s ease-out;
          width:24px;
      }
      nav .menu-icon .nav-icon:before,
      nav .menu-icon .nav-icon:after{
          background:rgb(255, 255, 255);
          content:'';
          display:block;
          height:100%;
          position:absolute;
          transition:all ease-out 0.2s;
          width:100%;
      }
      nav .menu-icon .nav-icon:before{
          top:6px;
      }
      nav .menu-icon .nav-icon:after{
          top:-6px;
      }
      nav .menu-btn:checked ~ .menu-icon .nav-icon{
          background:transparent;
      }
      nav .menu-btn:checked ~ .menu-icon .nav-icon:before{
          transform: rotate(-45deg);
          top:0;
      }
      nav .menu-btn:checked ~ .menu-icon .nav-icon:after{
          transform: rotate(45deg);
          top:0;
      }
      nav .menu-btn{
          display:none;
      }
      nav .menu-btn:checked ~ .menu{
          display:block;
      }}
      @media(max-width:400px){
          .a-box{
              width: 100%;
              height:400px;
          }
      }
